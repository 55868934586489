.bardmap {
  @apply max-w-screen-max mx-auto;
}
.bardmap h1 {
  @apply font-serif font-extrabold mb-6;
  font-size: 2.3rem; 
  font-weight: 700;
}

.bardmap h2 {
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  line-height: 1.4;
  @apply font-sans max-w-xl mb-6;
}

.bardmap h3 {
  font-size: clamp(1.4rem, 3vw, 2rem);
  line-height: 1;
  @apply font-sans pb-4;
}

.bardmap ul {
  @apply list-disc ml-5;
}

.bardmap p {
  @apply mb-4;
}

.bardmap__images-container {
  @apply flex flex-col flex-wrap items-center justify-center my-6;
}

.bardmap__image {
  @apply border-b-8;
}

.bardmap__image.initial {
  @apply border-l-0;
}

.bardmap__image.last {
  @apply border-r-0;
}

.map-container {
  @apply mx-auto max-w-screen-max;
  height: 500px;
}

@screen md {

  .bardmap__images-container {
    @apply flex flex-row flex-wrap items-center justify-center;
  }

  .bardmap__image {
    @apply w-1/3 border-4;
  }
}

@screen xxl {

  .bardmap h2 {
    @apply max-w-4xl;
  }

  .bardmap__image {
    @apply w-1/2;
  }
}