footer {
    @apply bg-white flex flex-col items-center mx-auto max-w-screen-max;
}

.footer__container {
    @apply max-w-8xl mx-auto w-full flex flex-col flex-wrap justify-between items-center;
}

.footer__links {
    @apply w-50;
}

.social-links ul {
    @apply mx-auto;
}

.footer__links ul {
    @apply flex flex-col items-center mb-8;
}

.footer__links li {
    @apply my-3;
}

.footer__links span {
    @apply hidden;
}

.footer__text {
    @apply w-full mt-5;
}

.footer__text p {
    @apply text-center;
}

.footer__text p a {
    @apply text-brand-blueLight;
}

.footer__top-container {
    @apply flex flex-col flex-wrap mx-auto;
}

.footer__left {
    @apply w-full;

    & h3 {
        @apply mt-2 mb-3;
    }
}

.footer__right {
    @apply w-full;
}

@screen lg {
    .footer__container {
        @apply flex-row;
    }

    .footer__links ul {
        @apply mb-0 flex-row items-start;
    }

    .footer__links li {
        @apply my-0;
    }

    .footer__links span {
        @apply block;
    }

    .footer__text {
        @apply flex flex-row;
    }

    .footer__text p:last-child {
        @apply ml-1;
    }

    .footer__text p {
        @apply text-left;
    }

    .footer__left {
    
        & h3 {
            @apply mb-5;
        }
    }
}
