.nav-container {
    @apply w-full z-40;
    position: relative;
}

.nav {
    position: relative;
}

.nav-link {
    @apply text-brand-primary uppercase font-bold;
}

.nav-link:hover {
    @apply opacity-75;
}

.nav-link:nth-child(4):after {
    content: '\2304';
    position: relative;
    top: -3px;
}

.nav-link:nth-child(4):hover ~ .nav__dropdown,
.nav__dropdown:hover {
    display: flex;
}

.nav-link--active {
    @apply text-brand-primary;
}

.nav__dropdown {
    @apply bg-brand-white;
    display: none;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 28px;
}

.nav__dropdown .nav-link {
    margin: 0;
    padding: 1rem;
    text-align: right;
}

.nav__dropdown .nav-link:first-child {
    padding-top: 1.5rem;
}

.nav__dropdown .nav-link:last-child {
    padding-bottom: 1.5rem;
}

.mobile-nav-children {
    @apply flex flex-col items-start ml-5;
}

.mobile-nav-children .nav-link {
    @apply mb-5;
}

/* .mobile-nav {
    @apply text-base lg:text-lg bg-white;
}

.mobile-nav-container {
    @apply flex flex-col;
    gap: var(--space-xs);
    padding: var(--space-xs-s) var(--space-s-xl);
} */

.mobile-nav-open {
    @apply opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right z-50;
}

.mobile-nav-closed {
    @apply opacity-0 scale-95 absolute inset-x-0 p-2 transition transform origin-top-right;
    top: -100vh;
}

@screen lg {

    .nav-link {
        @apply mx-4;
    }

    .nav-link:last-of-type {
        @apply ml-6;
    }

    /* .nav__dropdown {
        @apply hidden;
    } */

    .mobile-nav-closed,
    .mobile-nav-open {
        @apply hidden;
    }
}