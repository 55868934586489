.bard h1 {
  @apply font-serif font-extrabold mb-6;
  font-size: 2.3rem; 
  font-weight: 700;
}

.bard h2 {
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  line-height: 1.4;
  @apply font-sans max-w-xl mb-6;
}

.bard h3 {
  font-size: clamp(1.4rem, 3vw, 2rem);
  line-height: 1;
  @apply font-sans pb-4;
}

.bard ul {
  @apply list-disc ml-5;
}

.bard p {
  @apply mb-4;
  font-size: clamp(1rem, 3vw, 1.125rem);
  letter-spacing: .04em;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 2;
}

.bard__images-container {
  @apply flex flex-col flex-wrap items-center justify-center mt-5;
}

.bard__image {
  @apply border-b-8;
}

.bard__image.initial {
  @apply border-l-0;
}

.bard__image.last {
  @apply border-r-0;
}

@screen md {

  .bard__images-container {
    @apply flex flex-row flex-wrap items-center justify-center;
  }

  .bard__image {
    @apply w-1/3 border-4;
  }
}

@screen xxl {

  .bard h2 {
    @apply max-w-4xl;
  }

  .bard__images-container {
    @apply mt-10;
  }

  .bard__image {
      @apply w-1/2;
  }
}