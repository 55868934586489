.hidden-phone {
    display: none;
}

.hero {
    @apply mx-auto;
    height: calc(100vh - 8rem);
}

.hero__container {
    @apply flex items-center justify-center relative h-full overflow-hidden;
}

.hero__inner {
    @apply absolute flex flex-col items-center justify-center h-full w-full px-4;
    max-width: 88rem;
}

.hero__text-container {
    @apply relative mb-10;
}

.hero__heading {
    @apply font-serif font-extrabold text-left;
    font-size: 2.3rem; 
    font-weight: 700;
}

.hero__pre-text {
    @apply uppercase text-left;
}

.hero__post-text {
    @apply text-left;
}

.hero__form {
    @apply relative bg-brand-white rounded-lg w-full;
}

.hero__form form {
    @apply flex;
}

.hero__form input {
    @apply rounded-lg;
}

.hero__form input::placeholder {
    @apply text-sm text-brand-gray/60 !important;
}

.hero__form button {
    @apply bg-brand-orange uppercase text-sm font-semibold tracking-wider rounded-lg p-2 m-1;
    min-width: 25%;
}

.hero__form button:after {
    @apply text-lg;
    vertical-align: -30%;
}

.hero__form form input[type="email"] {
    @apply text-base !important;
}

@screen xs {

    .hero__heading {
        font-size: 2.5rem;
    }

}

@screen sm {

    .hero__text-container,
    .hero__form {
        @apply w-90;
    }

    .hero__form button:after {
        @apply text-xl;
    }
}

@screen md {

    .hero__text-container,
    .hero__form {
        @apply w-80;
    }

    .hero__heading {
        font-size: 3rem;
    }

    .hero__form button {
        @apply text-brand-orange bg-brand-white uppercase font-semibold tracking-wider text-base text-right p-0 m-5;
        min-width: 40%;
    }

    .hero__form button:after {
        @apply text-base;
        content: "\01F862";
        margin: 0 .5rem;
        position: relative;
        right: 0;
        -webkit-transition: all 0.75s;
        transition: all 0.75s;
        vertical-align: 0%;
    }

    .hero__form button:hover {
        @apply opacity-75;
    }

    .hero__form button:hover:after {
        right: -10px;
    }

    .hero__form p {
        @apply text-gray-500 font-sans text-center p-5;
    }

    .hidden-phone {
        @apply inline;
    }
}

@screen lg {

    .hero__text-container,
    .hero__form {
        @apply w-70;
    }

    .hero__form button {
        @apply pr-0 pb-0 pl-0 pt-1;
        min-width: 28%;
    }

    .hero__heading {
        font-size: 3.5rem;
    }
/* 
    .hero__inner {
        transform: translate(0, 0);
    } */
}

@screen xl {

    .hero__text-container,
    .hero__form {
        @apply w-50;
    }

    /* .hero__inner {
        transform: translate(0, -8rem);
    } */
}

@screen xxl {

    .hero__inner {
        @apply items-end p-0;
    }

    .hero__form {
        @apply absolute bottom-20;
    }

    .hero__text-container,
    .hero__form {
        max-width: 45%;
    }
}