*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: Montserrat, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

:root {
  --color-transparent: transparent;
  --color-current: currentColor;
  --color-brand-white: #fff;
  --color-brand-black: #222;
  --color-brand-gray: #2d3a4c;
  --color-brand-grayLight: #eee;
  --color-brand-bluePrimary: #016699;
  --color-brand-blueLight: #00a1e8;
  --color-brand-yellow: #f7b100;
  --color-brand-orange: #f47f00;
  --color-brand-socialp: #00a1e8;
  --color-brand-socials: #1b3d6a;
  --color-brand-primary: #1b3d6a;
  --color-brand-secondary: #016699;
  --color-black: #000;
  --color-white: #fff;
  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;
  --color-red-50: #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;
  --color-yellow-50: #fffbeb;
  --color-yellow-100: #fef3c7;
  --color-yellow-200: #fde68a;
  --color-yellow-300: #fcd34d;
  --color-yellow-400: #fbbf24;
  --color-yellow-500: #f59e0b;
  --color-yellow-600: #d97706;
  --color-yellow-700: #b45309;
  --color-yellow-800: #92400e;
  --color-yellow-900: #78350f;
  --color-green-50: #ecfdf5;
  --color-green-100: #d1fae5;
  --color-green-200: #a7f3d0;
  --color-green-300: #6ee7b7;
  --color-green-400: #34d399;
  --color-green-500: #10b981;
  --color-green-600: #059669;
  --color-green-700: #047857;
  --color-green-800: #065f46;
  --color-green-900: #064e3b;
  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-200: #bfdbfe;
  --color-blue-300: #93c5fd;
  --color-blue-400: #60a5fa;
  --color-blue-500: #3b82f6;
  --color-blue-600: #2563eb;
  --color-blue-700: #1d4ed8;
  --color-blue-800: #1e40af;
  --color-blue-900: #1e3a8a;
  --color-indigo-50: #eef2ff;
  --color-indigo-100: #e0e7ff;
  --color-indigo-200: #c7d2fe;
  --color-indigo-300: #a5b4fc;
  --color-indigo-400: #818cf8;
  --color-indigo-500: #6366f1;
  --color-indigo-600: #4f46e5;
  --color-indigo-700: #4338ca;
  --color-indigo-800: #3730a3;
  --color-indigo-900: #312e81;
  --color-purple-50: #f5f3ff;
  --color-purple-100: #ede9fe;
  --color-purple-200: #ddd6fe;
  --color-purple-300: #c4b5fd;
  --color-purple-400: #a78bfa;
  --color-purple-500: #8b5cf6;
  --color-purple-600: #7c3aed;
  --color-purple-700: #6d28d9;
  --color-purple-800: #5b21b6;
  --color-purple-900: #4c1d95;
  --color-pink-50: #fdf2f8;
  --color-pink-100: #fce7f3;
  --color-pink-200: #fbcfe8;
  --color-pink-300: #f9a8d4;
  --color-pink-400: #f472b6;
  --color-pink-500: #ec4899;
  --color-pink-600: #db2777;
  --color-pink-700: #be185d;
  --color-pink-800: #9d174d;
  --color-pink-900: #831843;
  --screen-xs: 480px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-xxl: 1536px;
  --screen-max: 1920px;
  --font-sans: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-serif: poppins, Hepta Slab, Georgia, Cambria, "Times New Roman", Times, serif;
  --font-mono: Montserrat, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --text-xs: .75rem;
  --text-xs-line-height: 1rem;
  --text-sm: .875rem;
  --text-sm-line-height: 1.25rem;
  --text-base: 1rem;
  --text-base-line-height: 1.5rem;
  --text-lg: 1.125rem;
  --text-lg-line-height: 1.75rem;
  --text-xl: 1.25rem;
  --text-xl-line-height: 1.75rem;
  --text-2xl: 1.5rem;
  --text-2xl-line-height: 2rem;
  --text-3xl: 1.875rem;
  --text-3xl-line-height: 2.25rem;
  --text-4xl: 2.25rem;
  --text-4xl-line-height: 2.5rem;
  --text-5xl: 3rem;
  --text-5xl-line-height: 1;
  --text-6xl: 3.75rem;
  --text-6xl-line-height: 1;
  --text-7xl: 4.5rem;
  --text-7xl-line-height: 1;
  --text-8xl: 6rem;
  --text-8xl-line-height: 1;
  --text-9xl: 8rem;
  --text-9xl-line-height: 1;
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-black: 900;
  --leading-3: .75rem;
  --leading-4: 1rem;
  --leading-5: 1.25rem;
  --leading-6: 1.5rem;
  --leading-7: 1.75rem;
  --leading-8: 2rem;
  --leading-9: 2.25rem;
  --leading-10: 2.5rem;
  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-snug: 1.375;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  --leading-loose: 2;
  --tracking-tighter: -.05em;
  --tracking-tight: -.025em;
  --tracking-normal: 0em;
  --tracking-wide: .025em;
  --tracking-wider: .05em;
  --tracking-widest: .1em;
  --bg-auto: auto;
  --bg-cover: cover;
  --bg-contain: contain;
  --border-0: 0px;
  --border-2: 2px;
  --border-4: 4px;
  --border-8: 8px;
  --border-default: 1px;
  --rounded-none: 0px;
  --rounded-sm: .125rem;
  --rounded-default: .25rem;
  --rounded-md: .375rem;
  --rounded-lg: .5rem;
  --rounded-xl: .75rem;
  --rounded-2xl: 1rem;
  --rounded-3xl: 1.5rem;
  --rounded-full: 9999px;
  --w-0: 0px;
  --w-1: .25rem;
  --w-2: .5rem;
  --w-3: .75rem;
  --w-4: 1rem;
  --w-5: 1.25rem;
  --w-6: 1.5rem;
  --w-7: 1.75rem;
  --w-8: 2rem;
  --w-9: 2.25rem;
  --w-10: 10%;
  --w-11: 2.75rem;
  --w-12: 3rem;
  --w-14: 3.5rem;
  --w-16: 4rem;
  --w-20: 20%;
  --w-24: 6rem;
  --w-28: 7rem;
  --w-30: 30%;
  --w-32: 8rem;
  --w-36: 9rem;
  --w-40: 40%;
  --w-44: 11rem;
  --w-48: 12rem;
  --w-50: 50%;
  --w-52: 13rem;
  --w-56: 14rem;
  --w-60: 60%;
  --w-64: 16rem;
  --w-70: 70%;
  --w-72: 18rem;
  --w-80: 80%;
  --w-90: 90%;
  --w-96: 24rem;
  --w-auto: auto;
  --w-px: 1px;
  --w-0_5: .125rem;
  --w-1_5: .375rem;
  --w-2_5: .625rem;
  --w-3_5: .875rem;
  --w-1-2: 50%;
  --w-1-3: 33.3333%;
  --w-2-3: 66.6667%;
  --w-1-4: 25%;
  --w-2-4: 50%;
  --w-3-4: 75%;
  --w-1-5: 20%;
  --w-2-5: 40%;
  --w-3-5: 60%;
  --w-4-5: 80%;
  --w-1-6: 16.6667%;
  --w-2-6: 33.3333%;
  --w-3-6: 50%;
  --w-4-6: 66.6667%;
  --w-5-6: 83.3333%;
  --w-1-12: 8.33333%;
  --w-2-12: 16.6667%;
  --w-3-12: 25%;
  --w-4-12: 33.3333%;
  --w-5-12: 41.6667%;
  --w-6-12: 50%;
  --w-7-12: 58.3333%;
  --w-8-12: 66.6667%;
  --w-9-12: 75%;
  --w-10-12: 83.3333%;
  --w-11-12: 91.6667%;
  --w-full: 100%;
  --w-screen: 100vw;
  --w-min: min-content;
  --w-max: max-content;
  --h-0: 0px;
  --h-1: .25rem;
  --h-2: .5rem;
  --h-3: .75rem;
  --h-4: 1rem;
  --h-5: 1.25rem;
  --h-6: 1.5rem;
  --h-7: 1.75rem;
  --h-8: 2rem;
  --h-9: 2.25rem;
  --h-10: 10%;
  --h-11: 2.75rem;
  --h-12: 3rem;
  --h-14: 3.5rem;
  --h-16: 4rem;
  --h-20: 20%;
  --h-24: 6rem;
  --h-28: 7rem;
  --h-30: 30%;
  --h-32: 8rem;
  --h-36: 9rem;
  --h-40: 40%;
  --h-44: 11rem;
  --h-48: 12rem;
  --h-50: 50%;
  --h-52: 13rem;
  --h-56: 14rem;
  --h-60: 60%;
  --h-64: 16rem;
  --h-70: 70%;
  --h-72: 18rem;
  --h-80: 80%;
  --h-90: 90%;
  --h-96: 24rem;
  --h-auto: auto;
  --h-px: 1px;
  --h-0_5: .125rem;
  --h-1_5: .375rem;
  --h-2_5: .625rem;
  --h-3_5: .875rem;
  --h-1-2: 50%;
  --h-1-3: 33.3333%;
  --h-2-3: 66.6667%;
  --h-1-4: 25%;
  --h-2-4: 50%;
  --h-3-4: 75%;
  --h-1-5: 20%;
  --h-2-5: 40%;
  --h-3-5: 60%;
  --h-4-5: 80%;
  --h-1-6: 16.6667%;
  --h-2-6: 33.3333%;
  --h-3-6: 50%;
  --h-4-6: 66.6667%;
  --h-5-6: 83.3333%;
  --h-full: 100%;
  --h-screen: 100vh;
  --min-w-0: 0px;
  --min-w-full: 100%;
  --min-w-min: min-content;
  --min-w-max: max-content;
  --min-h-0: 0px;
  --min-h-full: 100%;
  --min-h-screen: 100vh;
  --max-w-0: 0rem;
  --max-w-10: 10%;
  --max-w-20: 20%;
  --max-w-30: 30%;
  --max-w-40: 40%;
  --max-w-50: 50%;
  --max-w-60: 60%;
  --max-w-70: 70%;
  --max-w-80: 80%;
  --max-w-90: 90%;
  --max-w-none: none;
  --max-w-xs: 20rem;
  --max-w-sm: 24rem;
  --max-w-md: 28rem;
  --max-w-lg: 32rem;
  --max-w-xl: 36rem;
  --max-w-2xl: 42rem;
  --max-w-3xl: 48rem;
  --max-w-4xl: 56rem;
  --max-w-5xl: 64rem;
  --max-w-6xl: 72rem;
  --max-w-7xl: 80rem;
  --max-w-8xl: 88rem;
  --max-w-full: 100%;
  --max-w-min: min-content;
  --max-w-max: max-content;
  --max-w-prose: 65ch;
  --max-w-screen-xs: 480px;
  --max-w-screen-sm: 640px;
  --max-w-screen-md: 768px;
  --max-w-screen-lg: 1024px;
  --max-w-screen-xl: 1280px;
  --max-w-screen-xxl: 1536px;
  --max-w-screen-max: 1920px;
  --max-h-0: 0px;
  --max-h-1: .25rem;
  --max-h-2: .5rem;
  --max-h-3: .75rem;
  --max-h-4: 1rem;
  --max-h-5: 1.25rem;
  --max-h-6: 1.5rem;
  --max-h-7: 1.75rem;
  --max-h-8: 2rem;
  --max-h-9: 2.25rem;
  --max-h-10: 10%;
  --max-h-11: 2.75rem;
  --max-h-12: 3rem;
  --max-h-14: 3.5rem;
  --max-h-16: 4rem;
  --max-h-20: 20%;
  --max-h-24: 6rem;
  --max-h-28: 7rem;
  --max-h-30: 30%;
  --max-h-32: 8rem;
  --max-h-36: 9rem;
  --max-h-40: 40%;
  --max-h-44: 11rem;
  --max-h-48: 12rem;
  --max-h-50: 50%;
  --max-h-52: 13rem;
  --max-h-56: 14rem;
  --max-h-60: 60%;
  --max-h-64: 16rem;
  --max-h-70: 70%;
  --max-h-72: 18rem;
  --max-h-80: 80%;
  --max-h-90: 90%;
  --max-h-96: 24rem;
  --max-h-px: 1px;
  --max-h-0_5: .125rem;
  --max-h-1_5: .375rem;
  --max-h-2_5: .625rem;
  --max-h-3_5: .875rem;
  --max-h-full: 100%;
  --max-h-screen: 100vh;
  --p-0: 0px;
  --p-1: .25rem;
  --p-2: .5rem;
  --p-3: .75rem;
  --p-4: 1rem;
  --p-5: 1.25rem;
  --p-6: 1.5rem;
  --p-7: 1.75rem;
  --p-8: 2rem;
  --p-9: 2.25rem;
  --p-10: 10%;
  --p-11: 2.75rem;
  --p-12: 3rem;
  --p-14: 3.5rem;
  --p-16: 4rem;
  --p-20: 20%;
  --p-24: 6rem;
  --p-28: 7rem;
  --p-30: 30%;
  --p-32: 8rem;
  --p-36: 9rem;
  --p-40: 40%;
  --p-44: 11rem;
  --p-48: 12rem;
  --p-50: 50%;
  --p-52: 13rem;
  --p-56: 14rem;
  --p-60: 60%;
  --p-64: 16rem;
  --p-70: 70%;
  --p-72: 18rem;
  --p-80: 80%;
  --p-90: 90%;
  --p-96: 24rem;
  --p-px: 1px;
  --p-0_5: .125rem;
  --p-1_5: .375rem;
  --p-2_5: .625rem;
  --p-3_5: .875rem;
  --m-0: 0px;
  --m-1: .25rem;
  --m-2: .5rem;
  --m-3: .75rem;
  --m-4: 1rem;
  --m-5: 1.25rem;
  --m-6: 1.5rem;
  --m-7: 1.75rem;
  --m-8: 2rem;
  --m-9: 2.25rem;
  --m-10: 10%;
  --m-11: 2.75rem;
  --m-12: 3rem;
  --m-14: 3.5rem;
  --m-16: 4rem;
  --m-20: 20%;
  --m-24: 6rem;
  --m-28: 7rem;
  --m-30: 30%;
  --m-32: 8rem;
  --m-36: 9rem;
  --m-40: 40%;
  --m-44: 11rem;
  --m-48: 12rem;
  --m-50: 50%;
  --m-52: 13rem;
  --m-56: 14rem;
  --m-60: 60%;
  --m-64: 16rem;
  --m-70: 70%;
  --m-72: 18rem;
  --m-80: 80%;
  --m-90: 90%;
  --m-96: 24rem;
  --m-auto: auto;
  --m-px: 1px;
  --m-0_5: .125rem;
  --m-1_5: .375rem;
  --m-2_5: .625rem;
  --m-3_5: .875rem;
  --m--0: -0px;
  --m--1: -.25rem;
  --m--2: -.5rem;
  --m--3: -.75rem;
  --m--4: -1rem;
  --m--5: -1.25rem;
  --m--6: -1.5rem;
  --m--7: -1.75rem;
  --m--8: -2rem;
  --m--9: -2.25rem;
  --m--10: -10%;
  --m--11: -2.75rem;
  --m--12: -3rem;
  --m--14: -3.5rem;
  --m--16: -4rem;
  --m--20: -20%;
  --m--24: -6rem;
  --m--28: -7rem;
  --m--30: -30%;
  --m--32: -8rem;
  --m--36: -9rem;
  --m--40: -40%;
  --m--44: -11rem;
  --m--48: -12rem;
  --m--50: -50%;
  --m--52: -13rem;
  --m--56: -14rem;
  --m--60: -60%;
  --m--64: -16rem;
  --m--70: -70%;
  --m--72: -18rem;
  --m--80: -80%;
  --m--90: -90%;
  --m--96: -24rem;
  --m--px: -1px;
  --m--0_5: -.125rem;
  --m--1_5: -.375rem;
  --m--2_5: -.625rem;
  --m--3_5: -.875rem;
  --shadows-sm: 0 1px 2px 0 #0000000d;
  --shadows-default: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  --shadows-md: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  --shadows-lg: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  --shadows-xl: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  --shadows-2xl: 0 25px 50px -12px #00000040;
  --shadows-inner: inset 0 2px 4px 0 #0000000f;
  --shadows-none: none;
  --z-0: 0;
  --z-10: 10;
  --z-20: 20;
  --z-30: 30;
  --z-40: 40;
  --z-50: 50;
  --z-auto: auto;
  --opacity-0: 0;
  --opacity-5: .05;
  --opacity-10: .1;
  --opacity-20: .2;
  --opacity-25: .25;
  --opacity-30: .3;
  --opacity-40: .4;
  --opacity-50: .5;
  --opacity-60: .6;
  --opacity-70: .7;
  --opacity-75: .75;
  --opacity-80: .8;
  --opacity-90: .9;
  --opacity-95: .95;
  --opacity-100: 1;
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.z-0 {
  z-index: 0;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.-ml-px {
  margin-left: -1px;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-4 {
  height: 1rem;
}

.h-full {
  height: 100%;
}

.h-80 {
  height: 80%;
}

.h-8 {
  height: 2rem;
}

.h-20 {
  height: 20%;
}

.h-5 {
  height: 1.25rem;
}

.w-4 {
  width: 1rem;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-8 {
  width: 2rem;
}

.w-56 {
  width: 14rem;
}

.w-5 {
  width: 1.25rem;
}

.max-w-screen-max {
  max-width: 1920px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.flex-1 {
  flex: 1;
}

.cursor-default {
  cursor: default;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-y-8 {
  row-gap: 2rem;
}

.break-all {
  word-break: break-all;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-brand-primary {
  --tw-border-opacity: 1;
  border-color: rgb(27 61 106 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.bg-brand-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(1 102 153 / var(--tw-bg-opacity));
}

.bg-brand-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(27 61 106 / var(--tw-bg-opacity));
}

.bg-brand-white, .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-3 {
  padding: .75rem;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: .5rem;
}

.p-1 {
  padding: .25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-10 {
  padding-left: 10%;
  padding-right: 10%;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-serif {
  font-family: poppins, Hepta Slab, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.leading-normal {
  line-height: 1.5;
}

.leading-5 {
  line-height: 1.25rem;
}

.text-brand-primary {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-brand-white, .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.opacity-75 {
  opacity: .75;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:text-brand-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.active\:text-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.active\:text-gray-500:active {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:px-20 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 1536px) {
  .xxl\:max-w-4xl {
    max-width: 56rem;
  }

  .xxl\:list-none {
    list-style-type: none;
  }

  .xxl\:pb-0 {
    padding-bottom: 0;
  }

  .xxl\:text-center {
    text-align: center;
  }
}

@keyframes lum-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lum-fadeZoom {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lum-loader-rotate {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  50% {
    transform: translate(-50%, -50%)rotate(-180deg);
  }

  100% {
    transform: translate(-50%, -50%)rotate(-360deg);
  }
}

@keyframes lum-loader-before {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.2)translateX(6px);
  }

  25% {
    transform: scale(1.3)translateX(8px);
  }

  40% {
    transform: scale(1.2)translateX(6px);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(.8)translateX(6px);
  }

  75% {
    transform: scale(.7)translateX(8px);
  }

  90% {
    transform: scale(.8)translateX(6px);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lum-loader-after {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.2)translateX(-6px);
  }

  25% {
    transform: scale(1.3)translateX(-8px);
  }

  40% {
    transform: scale(1.2)translateX(-6px);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(.8)translateX(-6px);
  }

  75% {
    transform: scale(.7)translateX(-8px);
  }

  90% {
    transform: scale(.8)translateX(-6px);
  }

  100% {
    transform: scale(1);
  }
}

.lum-lightbox {
  background: #0009;
}

.lum-lightbox-inner {
  inset: 2.5%;
}

.lum-lightbox-inner img {
  position: relative;
}

.lum-lightbox-inner .lum-lightbox-caption {
  color: #fff;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

.lum-loading .lum-lightbox-loader {
  width: 66px;
  height: 20px;
  animation: 1.8s linear infinite lum-loader-rotate;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lum-lightbox-loader:before, .lum-lightbox-loader:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #ffffffe6;
  border-radius: 20px;
  margin-top: -10px;
  display: block;
  position: absolute;
  top: 50%;
}

.lum-lightbox-loader:before {
  animation: 1.8s linear infinite lum-loader-before;
  left: 0;
}

.lum-lightbox-loader:after {
  animation: 1.8s linear -900ms infinite lum-loader-after;
  right: 0;
}

.lum-lightbox.lum-opening {
  animation: .18s ease-out lum-fade;
}

.lum-lightbox.lum-opening .lum-lightbox-inner {
  animation: .18s ease-out lum-fadeZoom;
}

.lum-lightbox.lum-closing {
  animation: .3s ease-in reverse lum-fade;
}

.lum-lightbox.lum-closing .lum-lightbox-inner {
  animation: .3s ease-in reverse lum-fadeZoom;
}

.lum-img {
  transition: opacity .12s ease-out;
}

.lum-loading .lum-img {
  opacity: 0;
}

.lum-gallery-button {
  text-indent: 150%;
  white-space: nowrap;
  height: 100px;
  max-height: 100%;
  width: 60px;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.lum-close-button {
  width: 32px;
  height: 32px;
  opacity: .3;
  position: absolute;
  top: 5px;
  right: 5px;
}

.lum-close-button:hover {
  opacity: 1;
}

.lum-close-button:before, .lum-close-button:after {
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  left: 15px;
}

.lum-close-button:before {
  transform: rotate(45deg);
}

.lum-close-button:after {
  transform: rotate(-45deg);
}

.lum-previous-button {
  left: 12px;
}

.lum-next-button {
  right: 12px;
}

.lum-gallery-button:after {
  content: "";
  width: 36px;
  height: 36px;
  border-top: 4px solid #fffc;
  display: block;
  position: absolute;
  top: 50%;
}

.lum-previous-button:after {
  border-left: 4px solid #fffc;
  border-radius: 3px 0 0;
  left: 12%;
  transform: translateY(-50%)rotate(-45deg);
  box-shadow: -2px 0 #0003;
}

.lum-next-button:after {
  border-right: 4px solid #fffc;
  border-radius: 0 3px 0 0;
  right: 12%;
  transform: translateY(-50%)rotate(45deg);
  box-shadow: 2px 0 #0003;
}

@media (max-width: 460px) {
  .lum-lightbox-image-wrapper {
    -webkit-overflow-scrolling: touch;
    display: flex;
    overflow: auto;
  }

  .lum-lightbox-caption {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .lum-lightbox-position-helper {
    margin: auto;
  }

  .lum-lightbox-inner img {
    max-width: none;
    max-height: none;
  }
}

@font-face {
  font-family: Hind;
  font-style: normal;
  font-weight: 400;
  src: url("../hind-v15-latin-regular.13e231f3.eot");
  src: local(""), url("../hind-v15-latin-regular.13e231f3.eot#iefix") format("embedded-opentype"), url("../hind-v15-latin-regular.4a3c6f6a.woff2") format("woff2"), url("../hind-v15-latin-regular.fc7adfbb.woff") format("woff"), url("../hind-v15-latin-regular.e72b2bd0.ttf") format("truetype"), url("../hind-v15-latin-regular.37c51c21.svg#Hind") format("svg");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("../poppins-v19-latin-regular.ec9a47b8.eot");
  src: local(""), url("../poppins-v19-latin-regular.ec9a47b8.eot#iefix") format("embedded-opentype"), url("../poppins-v19-latin-regular.1110a71c.woff2") format("woff2"), url("../poppins-v19-latin-regular.79213dc5.woff") format("woff"), url("../poppins-v19-latin-regular.bfe26208.ttf") format("truetype"), url("../poppins-v19-latin-regular.a537560b.svg#Poppins") format("svg");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("../poppins-v19-latin-700.e5836ce5.eot");
  src: local(""), url("../poppins-v19-latin-700.e5836ce5.eot#iefix") format("embedded-opentype"), url("../poppins-v19-latin-700.0ebdad10.woff2") format("woff2"), url("../poppins-v19-latin-700.b19a170e.woff") format("woff"), url("../poppins-v19-latin-700.3e41e442.ttf") format("truetype"), url("../poppins-v19-latin-700.2ca11946.svg#Poppins") format("svg");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url("../poppins-v19-latin-800.2a4d5693.eot");
  src: local(""), url("../poppins-v19-latin-800.2a4d5693.eot#iefix") format("embedded-opentype"), url("../poppins-v19-latin-800.48eaf35d.woff2") format("woff2"), url("../poppins-v19-latin-800.10f19e8e.woff") format("woff"), url("../poppins-v19-latin-800.54d371ea.ttf") format("truetype"), url("../poppins-v19-latin-800.b297eaa2.svg#Poppins") format("svg");
}

@font-face {
  font-family: Brother;
  src: url("https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Brother;
  src: url("https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

.heading-1 {
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(2rem, 5vw, 4rem);
  line-height: 1.125;
}

.heading-2 {
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  line-height: 1.4;
}

.heading-3 {
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.5rem, 4vw, 2rem);
  line-height: 2;
}

.heading-4 {
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  line-height: 1.35;
}

.heading-5, .heading-6 {
  letter-spacing: .2em;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
}

.p {
  letter-spacing: .04em;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 2;
}

.p-base {
  font-size: clamp(1rem, 3vw, 1.125rem);
}

.p-small {
  font-size: clamp(.875rem, 3vw, 1rem);
}

a.anchor {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a.anchor:hover {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

a.anchor--primary {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a.anchor--primary:hover {
  --tw-text-opacity: 1;
  color: rgb(1 102 153 / var(--tw-text-opacity));
}

a.anchor--secondary {
  --tw-text-opacity: 1;
  color: rgb(1 102 153 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a.anchor--secondary:hover {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

.button {
  text-transform: uppercase;
  border-width: 0;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem .75rem;
  display: inline-flex;
}

.button--primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 161 232 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button--primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 61 106 / var(--tw-bg-opacity));
}

.button--secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 161 232 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button--secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

input {
  min-height: 80px;
  width: 100% !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(27 61 106 / var(--tw-text-opacity)) !important;
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
  padding-top: .25rem !important;
  padding-left: 2rem !important;
  font-size: clamp(1.25rem, 3vw, 1.375rem) !important;
}

textarea {
  min-height: 240px;
  width: 100% !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(27 61 106 / var(--tw-text-opacity)) !important;
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
  padding-top: 2rem !important;
  padding-left: 2rem !important;
  font-size: clamp(1.25rem, 3vw, 1.375rem) !important;
}

::placeholder {
  font-size: clamp(1.25rem, 3vw, 1.375rem);
  --tw-text-opacity: 1 !important;
  color: rgb(27 61 106 / var(--tw-text-opacity)) !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 1000px #fcf8f1 !important;
}

.section {
  padding: clamp(1rem, 2vw, 3rem);
}

.section--wide {
  padding: clamp(1rem, 2vw, 3rem) 0;
}

.pagination {
  max-width: max-content;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 4rem 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
}

.pagination li {
  height: 2.75rem;
  width: 2.75rem;
  cursor: pointer;
  border-radius: .75rem;
  justify-content: center;
  align-items: center;
  margin-left: .25rem;
  margin-right: .25rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.pagination li:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 61 106 / var(--tw-bg-opacity));
}

.pagination .prev:hover, .pagination .next:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 102 153 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

.contact {
  max-width: 1920px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding: clamp(2rem, 4vw, 6rem);
  display: flex;
}

.contact-container {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
}

.contact-form form input[type="text"], .contact-form form input[type="email"], .contact-form form input[type="tel"], .contact-form form textarea {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(27 61 106 / var(--tw-border-opacity));
}

.contact-form form input[type="text"]::placeholder, .contact-form form input[type="email"]::placeholder, .contact-form form input[type="tel"]::placeholder, .contact-form form textarea::placeholder {
  color: gray !important;
}

.contact-form button {
  --tw-bg-opacity: 1;
  background-color: rgb(0 161 232 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.contact-form button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 61 106 / var(--tw-bg-opacity));
}

.contact-form button {
  text-transform: uppercase;
  border-width: 0;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem .75rem;
  display: inline-flex;
}

@media (min-width: 1024px) {
  .contact-container {
    max-width: 36rem;
    min-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  }
}





.accordion {
  max-width: 1920px;
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.accordion__container {
  max-width: 48rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  row-gap: .75rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.accordion__title {
  margin-top: .15rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  transition: all .2s;
}

.accordion__header {
  cursor: pointer;
  min-height: 7.5rem;
  border-bottom: 1px solid var(--color-brand-primary-alpha);
  align-items: center;
  padding: 1rem 10%;
  display: flex;
}

.accordion__icon {
  height: max-content;
  transition: all .3s;
}

.accordion__item {
  border-bottom: 1px solid var(--color-brand-primary-alpha);
  transition: all .25s;
  position: relative;
}

.accordion__content {
  height: 0;
  transition: all .25s;
  overflow: hidden;
}

.accordion-open .accordion__icon {
  transform: rotate(90deg);
}

.bard h1 {
  margin-bottom: 1.5rem;
  font-family: poppins, Hepta Slab, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 2.3rem;
  font-weight: 700;
}

.bard h2 {
  max-width: 36rem;
  margin-bottom: 1.5rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  line-height: 1.4;
}

.bard h3 {
  padding-bottom: 1rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.4rem, 3vw, 2rem);
  line-height: 1;
}

.bard ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

.bard p {
  letter-spacing: .04em;
  margin-bottom: 1rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1rem, 3vw, 1.125rem);
  line-height: 2;
}

.bard__images-container {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  display: flex;
}

.bard__image {
  border-bottom-width: 8px;
}

.bard__image.initial {
  border-left-width: 0;
}

.bard__image.last {
  border-right-width: 0;
}

@media (min-width: 768px) {
  .bard__images-container {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .bard__image {
    width: 33.3333%;
    border-width: 4px;
  }
}

@media (min-width: 1536px) {
  .bard h2 {
    max-width: 56rem;
  }

  .bard__images-container {
    margin-top: 10%;
  }

  .bard__image {
    width: 50%;
  }
}

.bardmap {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.bardmap h1 {
  margin-bottom: 1.5rem;
  font-family: poppins, Hepta Slab, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 2.3rem;
  font-weight: 700;
}

.bardmap h2 {
  max-width: 36rem;
  margin-bottom: 1.5rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  line-height: 1.4;
}

.bardmap h3 {
  padding-bottom: 1rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1.4rem, 3vw, 2rem);
  line-height: 1;
}

.bardmap ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

.bardmap p {
  margin-bottom: 1rem;
}

.bardmap__images-container {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.bardmap__image {
  border-bottom-width: 8px;
}

.bardmap__image.initial {
  border-left-width: 0;
}

.bardmap__image.last {
  border-right-width: 0;
}

.map-container {
  max-width: 1920px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .bardmap__images-container {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .bardmap__image {
    width: 33.3333%;
    border-width: 4px;
  }
}

@media (min-width: 1536px) {
  .bardmap h2 {
    max-width: 56rem;
  }

  .bardmap__image {
    width: 50%;
  }
}

.cards {
  max-width: 1920px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
}

.cards__container {
  max-width: 88rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.card {
  width: 80%;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.card .card__image {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .card {
    width: 40%;
  }
}

@media (min-width: 1024px) {
  .card {
    width: 30%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1280px) {
  .card {
    width: 30%;
  }
}

footer {
  max-width: 1920px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer__container {
  width: 100%;
  max-width: 88rem;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer__links {
  width: 50%;
}

.social-links ul {
  margin-left: auto;
  margin-right: auto;
}

.footer__links ul {
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.footer__links li {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.footer__links span {
  display: none;
}

.footer__text {
  width: 100%;
  margin-top: 1.25rem;
}

.footer__text p {
  text-align: center;
}

.footer__text p a {
  --tw-text-opacity: 1;
  color: rgb(0 161 232 / var(--tw-text-opacity));
}

.footer__top-container {
  flex-flow: column wrap;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer__left {
  width: 100%;
}

.footer__left h3 {
  margin-top: .5rem;
  margin-bottom: .75rem;
}

.footer__right {
  width: 100%;
}

@media (min-width: 1024px) {
  .footer__container {
    flex-direction: row;
  }

  .footer__links ul {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .footer__links li {
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer__links span {
    display: block;
  }

  .footer__text {
    flex-direction: row;
    display: flex;
  }

  .footer__text p:last-child {
    margin-left: .25rem;
  }

  .footer__text p {
    text-align: left;
  }

  .footer__left h3 {
    margin-bottom: 1.25rem;
  }
}



.hidden-phone {
  display: none;
}

.hero {
  height: calc(100vh - 8rem);
  margin-left: auto;
  margin-right: auto;
}

.hero__container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero__inner {
  height: 100%;
  width: 100%;
  max-width: 88rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  position: absolute;
}

.hero__text-container {
  margin-bottom: 10%;
  position: relative;
}

.hero__heading {
  text-align: left;
  font-family: poppins, Hepta Slab, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 2.3rem;
  font-weight: 700;
}

.hero__pre-text {
  text-align: left;
  text-transform: uppercase;
}

.hero__post-text {
  text-align: left;
}

.hero__form {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .5rem;
  position: relative;
}

.hero__form form {
  display: flex;
}

.hero__form input {
  border-radius: .5rem;
}

.hero__form input::placeholder {
  color: #2d3a4c99 !important;
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.hero__form button {
  --tw-bg-opacity: 1;
  background-color: rgb(244 127 0 / var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  min-width: 25%;
  border-radius: .5rem;
  margin: .25rem;
  padding: .5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.hero__form button:after {
  vertical-align: -30%;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.hero__form form input[type="email"] {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

@media (min-width: 480px) {
  .hero__heading {
    font-size: 2.5rem;
  }
}

@media (min-width: 640px) {
  .hero__text-container, .hero__form {
    width: 90%;
  }

  .hero__form button:after {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .hero__text-container, .hero__form {
    width: 80%;
  }

  .hero__heading {
    font-size: 3rem;
  }

  .hero__form button {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    text-align: right;
    text-transform: uppercase;
    letter-spacing: .05em;
    --tw-text-opacity: 1;
    color: rgb(244 127 0 / var(--tw-text-opacity));
    min-width: 40%;
    margin: 1.25rem;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .hero__form button:after {
    content: "🡢";
    vertical-align: 0%;
    margin: 0 .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: all .75s;
    position: relative;
    right: 0;
  }

  .hero__form button:hover {
    opacity: .75;
  }

  .hero__form button:hover:after {
    right: -10px;
  }

  .hero__form p {
    text-align: center;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    padding: 1.25rem;
    font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .hidden-phone {
    display: inline;
  }
}

@media (min-width: 1024px) {
  .hero__text-container, .hero__form {
    width: 70%;
  }

  .hero__form button {
    min-width: 28%;
    padding: .25rem 0 0;
  }

  .hero__heading {
    font-size: 3.5rem;
  }
}

@media (min-width: 1280px) {
  .hero__text-container, .hero__form {
    width: 50%;
  }
}

@media (min-width: 1536px) {
  .hero__inner {
    align-items: flex-end;
    padding: 0;
  }

  .hero__form {
    position: absolute;
    bottom: 20%;
  }

  .hero__text-container, .hero__form {
    max-width: 45%;
  }
}

.image-aside {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.image-aside__container {
  max-width: 88rem;
  margin-left: auto;
  margin-right: auto;
}

.image-aside__container img {
  width: 100%;
  object-fit: cover;
  min-height: 20rem;
  max-height: 20rem;
  margin-top: 2rem;
}

@media (min-width: 1024px) {
  .image-aside__container {
    display: flex;
  }

  .image-aside__container .image-aside__text {
    flex: 45%;
  }

  .image-aside__container .image-aside__image {
    flex: 47%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .image-aside__container .image-aside__image img {
    width: 100%;
    min-height: 100%;
    max-height: 27rem;
    margin-top: 0;
  }

  .image--left {
    flex-direction: row-reverse;
  }

  .image--left .image-aside__text {
    margin-left: 5%;
  }

  .image--left .image-aside__image {
    margin-right: 3%;
  }

  .image--right .image-aside__text {
    margin-right: 5%;
  }

  .image--right .image-aside__image {
    margin-left: 3%;
  }
}

.image-aside-multi {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.image-aside-multi__text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-aside-multi__text h2, .image-aside-multi__text p {
  text-align: center;
}

.image-aside-multi__text .button {
  max-width: 250px;
}

.image-aside-multi__bard p {
  letter-spacing: .04em;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: hind, Brother, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: clamp(1rem, 3vw, 1.125rem);
  line-height: 2;
}

.image-aside-multi__carousel img {
  width: 100%;
  object-fit: cover;
  min-height: 20rem;
  max-height: 20rem;
}

.image-aside-multi__list {
  margin-left: 3rem;
}

.image-aside-multi__list li {
  margin-bottom: 1rem;
}

.image-aside-multi__list li:before {
  content: "";
  width: 42px;
  height: 37px;
  background: url("../check-icon.748fe034.svg");
  margin-left: -50px;
  display: block;
  position: absolute;
}

.image-aside-multi__images-wrapper {
  display: none;
}

.image-aside-multi__carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.image-aside-multi__carousel-wrapper * {
  box-sizing: border-box;
}

.image-aside-multi__carousel {
  transform-style: preserve-3d;
}

.image-aside-multi__carousel-photo {
  opacity: 0;
  width: 100%;
  z-index: 100;
  margin: auto;
  padding: 1rem 4rem;
  transition: transform .5s, opacity .5s, z-index .5s;
  position: absolute;
  top: 0;
}

.image-aside-multi__carousel-photo.initial, .image-aside-multi__carousel-photo.active {
  opacity: 1;
  z-index: 900;
  position: relative;
}

.image-aside-multi__carousel-photo.prev, .image-aside-multi__carousel-photo.next {
  z-index: 800;
}

.image-aside-multi__carousel-photo.prev {
  transform: translateX(-100%);
}

.image-aside-multi__carousel-photo.next {
  transform: translateX(100%);
}

.image-aside-multi__carousel-button--prev, .image-aside-multi__carousel-button--next {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  z-index: 1001;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image-aside-multi__carousel-button--prev {
  left: 0;
}

.image-aside-multi__carousel-button--next {
  right: 0;
}

.image-aside-multi__carousel-button--prev:after, .image-aside-multi__carousel-button--next:after {
  content: " ";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%)rotate(135deg);
}

.image-aside-multi__carousel-button--next:after {
  left: 47%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

@media (min-width: 1024px) {
  .image-aside-multi__container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .image--left {
    flex-direction: row-reverse;
  }

  .image-aside-multi__text {
    max-width: 50%;
    align-items: flex-start;
  }

  .image-aside-multi__text p {
    text-align: left;
  }

  .image-aside-multi__text .button {
    max-width: 250px;
  }

  .image-aside-multi__carousel-wrapper {
    display: none;
  }

  .image-aside-multi__images-wrapper {
    max-width: 50%;
    padding-left: 10%;
    padding-right: 10%;
    display: block;
  }

  .image-aside-multi__images {
    height: 100vh;
    max-width: 100%;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    display: flex;
  }

  .image-aside-multi__images-actual:nth-child(1), .image-aside-multi__images-actual:nth-child(2), .image-aside-multi__images-actual:nth-child(3) {
    width: 100%;
    object-fit: cover;
    height: 24.583%;
    max-width: 50%;
  }

  .image-aside-multi__images-actual:nth-child(4), .image-aside-multi__images-actual:nth-child(5) {
    width: 100%;
    object-fit: cover;
    height: 36.25%;
    max-width: 50%;
  }
}

.instagram {
  max-width: 1920px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
}

.nav-container {
  z-index: 40;
  width: 100%;
  position: relative;
}

.nav {
  position: relative;
}

.nav-link {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
  font-weight: 700;
}

.nav-link:hover {
  opacity: .75;
}

.nav-link:nth-child(4):after {
  content: "⌄";
  position: relative;
  top: -3px;
}

.nav-link:nth-child(4):hover ~ .nav__dropdown, .nav__dropdown:hover {
  display: flex;
}

.nav-link--active {
  --tw-text-opacity: 1;
  color: rgb(27 61 106 / var(--tw-text-opacity));
}

.nav__dropdown {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  display: none;
  position: absolute;
  top: 28px;
  right: 0;
}

.nav__dropdown .nav-link {
  text-align: right;
  margin: 0;
  padding: 1rem;
}

.nav__dropdown .nav-link:first-child {
  padding-top: 1.5rem;
}

.nav__dropdown .nav-link:last-child {
  padding-bottom: 1.5rem;
}

.mobile-nav-children {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.25rem;
  display: flex;
}

.mobile-nav-children .nav-link {
  margin-bottom: 1.25rem;
}

.mobile-nav-open {
  z-index: 50;
  transform-origin: 100% 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
  padding: .5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mobile-nav-closed {
  transform-origin: 100% 0;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  padding: .5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: -100vh;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .nav-link {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .nav-link:last-of-type {
    margin-left: 1.5rem;
  }

  .mobile-nav-closed, .mobile-nav-open {
    display: none;
  }
}

.social-links path {
  fill: var(--color-brand-socials);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.social-links li:hover path {
  fill: var(--color-brand-socialp);
}

.youtube {
  max-width: 1920px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
}

.youtube_container {
  max-width: 88rem;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.youtube_container__aspect {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
  position: relative;
}

.youtube_container__aspect > * {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
}

.youtube_container__aspect {
  --tw-aspect-h: 9;
}







/*# sourceMappingURL=site.css.map */
