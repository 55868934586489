.contact {
    @apply mx-auto flex flex-wrap;
    max-width: 1920px;
    padding: clamp(2rem, 4vw, 6rem);
}

.contact-container {
    @apply max-w-xl mx-auto;
}

.contact-header {

}

.contact-form form input[type=text],
.contact-form form input[type=email],
.contact-form form input[type=tel],
.contact-form form textarea {
    @apply border-solid border-2 border-brand-primary;
}

.contact-form form input[type=text]::placeholder,
.contact-form form input[type=email]::placeholder,
.contact-form form input[type=tel]::placeholder,
.contact-form form textarea::placeholder {
    color: grey !important;
}

.contact-form button {
    @apply bg-brand-blueLight text-brand-white transition-colors hover:bg-brand-primary;
    text-transform: uppercase;
    border-width: 0;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem .75rem;
    display: inline-flex;
}

@screen lg {

    .contact-container {
        @apply max-w-xl mx-auto;
        min-width: 36rem;
    }
}